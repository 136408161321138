:root {
  --primary: #241c19;
  --secondary: #F3A847;
  --tertiary: #B12704;
  --light: #fafafa;
  --dark: #000000;
  --darker: #111111;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);

}

* {
  box-sizing: border-box;
}



html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background-image: url("./assets//images/port-bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  margin: 20px 15px;
  line-height: 1.25;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.description {
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: bold;
  text-align: justify;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1px;
}

.descDiv {
  background-color: var(--secondary);
  margin-left: 5rem;
  margin-right: 5rem;
  padding-top: 1rem;
  margin-top: 5rem;
  padding-bottom: 0.2rem;
  border-radius: 50px;
}

.about-title {
  font-size: 2.25rem;
  color: var(--secondary);
  margin-left: 5rem;
}

.downloadDiv {
  text-align: center;
  color: var(--secondary);
  font-weight: bold;
}

.resume {
  color: var(--secondary);
  font-weight: bold;
}

.about,
.portfolio,
.contact {
  color: var(--secondary);
  text-decoration: none;
  font-weight: bold;
}

/* .about:hover,
.portfolio:hover,
.contact:hover,
.resume:hover {
  background-color: var(--secondary);
  color: var(--primary);
  padding: 5px;
  border-radius: 10px;
  border: 3px solid var(--secondary);
} */

.center {
	display: flex;
	justify-content: center;
}

.cardDiv {
  display: flex;
	justify-content: center;
}

.padding {
	padding: 50px;
}

.page-header {
	font-size: 2.5em;
	font-weight: bold;
	margin: 5px 0;
	line-height: 1.25;
	padding-bottom: 2rem;
  color: var(--secondary);
}

.mobile-row {
  display: block;
  margin: 0 100px 0 auto;
}

#main-card {
  width: 25rem;
  
}

#card-img {
  margin-left: auto;
  margin-right: auto;
}

.card {
	width: 25%;
	text-align: center;
}

.card-title {
	font-size: 1.5rem;
	color: var(--primary);
  font-weight: bold;
}

.card-subtitle {
	font-size: 1.2rem;
	color: var(--tertiary);
  margin-top: 15px;
}

.card-techs {
	font-size: 1rem;
	height: 4rem;
	color: var(--primary);
}

.card-image {
	max-width: 400px;
	max-height: 250px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-link {
	font-size: 1rem;
  color: var(--secondary);
  outline: none;
  text-decoration: none;
}

.portfolio-image {
  border-radius: 50px;
  display: block;
  border: 5px solid var(--secondary);
  width: 20vh;
  height: 20vh;
  margin-left: 5rem;
}

.portfolio-title {
  color: var(--primary);
  background-color: var(--secondary);
  padding: 5px;
  text-align: center;
  box-shadow: 2px 2px 8px #FF0000;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  text-decoration: none;
}

header {
  justify-content: space-between;
  margin-right: 15px;
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--primary);
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

.resume-title {
  color: var(--secondary);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.skillsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.skills-1 {
  background-color: var(--secondary);
  border-radius: 50px;
  text-align: left;
  padding: 5px;
  border: 5px solid var(--tertiary);
  margin-bottom: 10px;
}

.skills-2 {
  background-color: var(--secondary);
  border-radius: 50px;
  text-align: left;
  padding-left: 2px;
  list-style: circle;
  padding-right: 60px;
  border: 5px solid var(--tertiary);
}

.skills-1 li, .skills-2 li {
  list-style: square;
}

.resumeDiv {
  margin-left: auto;
  margin-right: auto;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: .5rem;
  border: 3px solid var(--secondary);
  border-radius: 15px;
  outline: none;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

.contact-title {
  color: var(--secondary);
  margin-top: 8rem;
}

.error-text {
  font-weight: bold;
  color: #FF0000;
  width: 60%;
  font-style: italic;
}

.input-title,
.message {
  color: var(--secondary);
  font-weight: bold;
  margin-left: 5px;
}

.contact-form {
  margin-left: 15px;
}

li {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

textarea {
	width: 600px;
	height: 120px;
	border: 3px solid var(--secondary);
	padding: 5px;
  border-radius: 15px;
  outline: none;
}

.copyright {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 11.25rem;
  margin-bottom: 10px;
}

.footer a {
  color: var(--secondary);
  text-decoration: none;
  font-weight: bold;
}

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.github-logo {
  width: 70px;
  height: 70px;
  background-color: var(--secondary);
  border-radius: 50px;
  border: 2px solid var(--secondary);
}

.linkedin-logo {
  width: 70px;
  height: 70px;
  border-radius: 15px;
}

.stack-logo {
  width: 70px;
  height: 70px;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.portfolio-list {
  justify-content: center;
  margin-top: 50px;
}

.project-list {
background-color: var(--secondary);
border-radius: 30px;
margin: 5px;
border: 8px solid var(--tertiary);
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 40%;
  text-align: center;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

button {
	border: 0;
	border-radius: 10px;
	color: var(--primary);
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;	
}

.submit-btn {
  color: var(--primary);
  background-color: var(--secondary);
}

.navActive {
  color: var(--tertiary);
  font-weight: bold;
}

@media only screen and (max-width: 950px) {
header {
  justify-content: center;
  }
}

@media only screen and (max-width: 875px) {
  .portfolio-image {
    margin-left: auto;
    margin-right: auto;
  }

  textarea {
    width: 300px;
	height: 90px;
  }

  input {
    width: 300px;
    height: 20px;
  }

  h1 {
    text-align: center;
    margin-left: 0;
  }

  p {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .portfolio-title {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }
  .about-title {
    margin-left: 0;
  }

  .descDiv {
    margin-left: 5px;
    margin-right: 5px;
  }

  /* .copyright {
    flex-direction: column;
  }  */
}

@media only screen and (max-width: 400px) {
  .portfolio-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
  }
}

